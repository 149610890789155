<template>
  <div class="d-print-none">
    <a v-for="link of links" v-bind:key="link.link" :href="link.link"
       target="_blank"
       class="btn btn-sm btn-primary m-1"
       :style="{'background-color': link.color, 'border-color': link.color}"
    ><i :class="link.icon" role="img" :aria-label="link.platform"></i> {{ link.platform }}
    </a>
    <button onclick="window.print()" class="btn btn-sm btn-danger m-1"><i class="bi bi-file-pdf" role="img" aria-label="Print PDF"></i> Print PDF</button>
  </div>
</template>

<script>
export default {
  name: "SocialNetworks",
  props: ["links"]
}
</script>

<style scoped>

</style>