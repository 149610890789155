<template>
  <div class="row">
    <h3>Internships</h3>
    <div class="col-md-12">
      <ul>
        <li v-for="item of internships" v-bind:key="item.company">
          <b>{{ item.company }}, {{
              item.location
            }}</b> {{ formatDate(item.startDate) }} - {{ formatDate(item.endDate) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "InternshipsSession",
  props: ["internships"],
  methods: {
    formatDate(value) {
      if (value === "") {
        return ""
      }
      const options = {year: 'numeric', month: 'short'};
      const targetDate = new Date(value);
      return targetDate.toLocaleDateString("en-US", options)
    }
  }
}
</script>

<style scoped>

</style>