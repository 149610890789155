<template>
    <ProfileItem v-bind:profile="profile[0]"></ProfileItem>
    <CoverLetter v-bind:cover-letter="coverLetter[0].coverLetter"></CoverLetter>
  <ExperienceItem v-bind:experiences="experience"></ExperienceItem>
  <InternshipsSession v-bind:internships="internships"></InternshipsSession>
  <EducationSession v-bind:education="education"></EducationSession>
  <LanguagesSession v-bind:languages="languages"></LanguagesSession>
  <div class="mb-4"></div>
</template>

<script>
import ProfileItem from "@/components/ProfileItem";
import resume from "../resume.json"
import CoverLetter from "@/components/CoverLetter";
import ExperienceItem from "@/components/ExperienceItem";
import EducationSession from "@/components/EducationSession";
import LanguagesSession from "@/components/LanguagesSession";
import InternshipsSession from "@/components/InternshipsSession";

export default {
  name: 'App',
  components: {
    InternshipsSession,
    LanguagesSession,
    EducationSession,
    ExperienceItem,
    CoverLetter,
    ProfileItem,
  },
  data() {
    return resume
  }
}
</script>

<style>
</style>
