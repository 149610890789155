<template>
  <div class="row">
    <h3 class="d-print-none">Resume</h3>
    <p>{{ coverLetter }}</p>
  </div>
</template>

<script>
export default {
  name: "CoverLetter",
  props: ["coverLetter"]
}
</script>

<style scoped>

</style>