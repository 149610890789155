<template>
  <div class="row">
    <h3>Education</h3>
    <div class="col-md-12">
      <ul>
        <li v-for="item of education" v-bind:key="item.course">
          <b>{{ item.institution }}, {{
              item.location
            }}</b> {{ formatDate(item.startDate) }} - {{ formatDate(item.endDate) }} {{ item.status }}<br>
          {{ item.degree }} - {{ item.course }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationSession",
  props: ["education"],
  methods: {
    formatDate(value) {
      if (value === "") {
        return ""
      }
      const options = {year: 'numeric', month: 'short'};
      const targetDate = new Date(value);
      return targetDate.toLocaleDateString("en-US", options)
    }
  }
}
</script>

<style scoped>

</style>