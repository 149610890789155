<template>
  <div class="row" style="display: block">
    <h3>Experience</h3>
    <div class="print-break" v-for="experience of experiences" v-bind:key="experience.Role">
      <h5>{{ experience.Role }} @ {{ experience.company }} <span class="no-style">{{ formatDate(experience.startDate) }} - {{ formatDate(experience.endDate) || "Current" }}</span></h5>
      <p class="d-print-none">{{ experience.companyDescription }}</p>
      <div v-for="project of experience.projects_json" v-bind:key="project">
        <h6>Project: {{project.name}}</h6>
        <ul>
          <li v-for="resp of project.responsibilities" v-bind:key="resp">{{resp}}</li>
        </ul>
      </div>
      <p><small class="text-muted">Technologies Used: {{experience.technologies}}</small></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExperienceItem",
  props: ["experiences"],
  methods: {
    formatDate(value) {
      if (value === "") {
        return ""
      }
      const options = {year: 'numeric', month: 'short'};
      const targetDate = new Date(value);
      return targetDate.toLocaleDateString("en-US", options)
    }
  }
}
</script>

<style scoped>
.no-style {
  font-size: 1rem;
  font-weight: 400;
}
.print-break {
   page-break-inside: avoid;
}
</style>