<template>
<div class="row">
  <h3>Languages</h3>
  <div class="col-md-12">
      <ul>
        <li v-for="item of languages" v-bind:key="item.language">
          <b>{{item.language}}</b> {{item.proficiency}}
        </li>
      </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "LanguagesSession",
  props: ["languages"]
}
</script>

<style scoped>

</style>