<template>
  <div class="row d-none d-sm-block">
    <div class="offset-md-11 col-md-1 p-2 d-print-none">
      <img
          src="../assets/qrcode.svg"
          title="{{profile.website}}"
          class="img-fluid"
          alt="{{profile.website}}"
      />
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col-lg-3 col-sm-3 col-xs-6 p-4 d-print-none">
      <img
          src="../assets/patrick-rodrigues.jpg"
          class="rounded-circle img-fluid"
          title="{{profile.name}}"
          alt="{{profile.name}}"
      />
    </div>
    <div class="col-lg-9 col-sm-9 col-xs-6">
      <h1>{{ profile.name }}</h1>
      <h2><small>{{ profile.jobTitle }}</small></h2>
      <span>{{profile.location}} | {{profile.email}} | +{{profile.phone}}</span>
      <hr>
      <SocialNetworks v-bind:links="profile.links_json"></SocialNetworks>
    </div>
  </div>
</template>

<script>
import SocialNetworks from "@/components/SocialNetworks";

export default {
  name: "ProfileItem",
  components: {
    SocialNetworks
  },

  props: ["profile"]
}
</script>

<style scoped>

</style>
